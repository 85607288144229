const prod = {
  url: {
    API_URL: "https://ffsubs-test-api.ogwebdesign.ca/api/",
  },
};

const dev = {
  url: {
    API_URL: "https://ffsubs-test-api.ogwebdesign.ca/api/",
  },
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
