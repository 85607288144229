import { Navigate, createBrowserRouter } from "react-router-dom";

import DefaultLayout from "../components/layouts/DefaultLayout";
import AuthenticateUser from "../views/authentication/AuthenticateUser";
import RequestEmailAuthentication from "../views/authentication/RequestEmailAuthentication";
import NotFound from "../views/notFound/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/email-authenticate" />,
      },
      {
        path: "/authenticate",
        element: <AuthenticateUser />,
      },
      {
        path: "/email-authenticate",
        element: <RequestEmailAuthentication />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default router;
