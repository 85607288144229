import * as actionTypes from "./actionTypes";

import { config } from "../../constants";

import axiosRegular from "../../axios";
import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authToken, fname, userId, storeId) => {
  localStorage.setItem("authToken", authToken);
  localStorage.setItem("fname", fname);
  localStorage.setItem("userId", userId);
  localStorage.setItem("storeId", storeId);

  return {
    type: actionTypes.AUTH_SUCCESS,
    authToken: authToken,
    fname: fname,
    userId: userId,
    storeId: storeId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const requestFailed = (error) => {
  return {
    type: actionTypes.REQUEST_FAILED,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("fname");
  localStorage.removeItem("userId");
  localStorage.removeItem("storeId");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationDateout) => {
  //   return (dispatch) => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationDateout * 1000);
  //   };
};

export const auth = (id, token) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {};
    let url =
      config.url.API_URL + "createSubscriberToken?id=" + id + "&token=" + token;

    axios
      .get(url, authData)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );

        dispatch(
          authSuccess(
            response.data.token,
            response.data.fname,
            response.data.user.id,
            response.data.user.store_id
          )
        );
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      console.log("authCheckState caused logout");
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      // } else {
      const authToken = localStorage.getItem("authToken");
      const fname = localStorage.getItem("fname");
      const userId = localStorage.getItem("userId");
      const storeId = localStorage.getItem("storeId");

      dispatch(authSuccess(authToken, fname, userId, storeId));
      // dispatch(
      //   checkAuthTimeout(
      //     (expirationDate.getTime() - new Date().getTime()) / 1000
      //   )
      // );
      // }
    }
  };
};
