import { useState } from "react";

import { makeGetApiCall } from "../shared/makeApiCall";
import { AuthenticationViewModel } from "./AuthenticationViewModel";
import ChangeSubscriptionForm from "../components/forms/ChangeSubscriptionForm";
import { makePostApiCall } from "../shared/makeApiCall";

import { updateObjectInArray } from "../shared/utility";
import { SimpleNotificationType } from "../components/notifications/SimpleNotification";

import { format } from "date-fns";

export function SubscriptionsViewModel() {
  const { storeId, userId } = AuthenticationViewModel();

  const [confirmationModal, setConfirmationModal] = useState({
    action: null,
    open: false,
    childComponent: null,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [frequencies, setFrequencies] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [showSimpleNoti, setShowSimpleNoti] = useState({
    open: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [skipSubscriptionModal, setSkipSubscriptionModal] = useState({
    action: null,
    open: false,
    childComponent: null,
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const [updateAmountModal, setUpdateAmountModal] = useState({
    open: false,
    childComponent: null,
  });

  const getUserSubscriptions = async function () {
    setLoading(true);
    let callResult = await makeGetApiCall("userSubscriptions?userId=" + userId);

    setSubscriptions(callResult.data);
    setLoading(false);
  };

  const getFrequencies = async function () {
    let callResult = await makeGetApiCall(
      `allStoreFrequenciesForDropdown?storeId=${storeId}`
    );

    setFrequencies(callResult.data);
  };

  const getFrequencyName = (type) => {
    var typeName = "";
    frequencies.forEach((element) => {
      if (element.value === type) {
        typeName = element.label;
      }
    });
    return typeName;
  };

  const updateAmountCall = async function (product, values, index) {
    setFormLoading(true);
    const payload = {
      amount: parseInt(values.amount),
      next_date: format(new Date(values.next_date), "yyyy-MM-dd"),
      deliveryDateId: parseInt(values.deliveryDateId),
      type: values.type,
    };
    let callResult = await makePostApiCall(
      `userSubscriptionUpdate/${product.id}`,
      payload
    );

    // starts updating product here
    const currentSubscriptions = [...subscriptions];
    const updatedSubscription = {
      ...subscriptions[index],
      amount: values.amount,
      next_date: values.next_date,
      type: values.type,
      frequency_name: getFrequencyName(values.type),
      frequency_value: values.type,
    };
    const updatePayload = {
      index: index,
      item: updatedSubscription,
    };
    const updatedSubscriptions = updateObjectInArray(
      currentSubscriptions,
      updatePayload
    );
    setSubscriptions(updatedSubscriptions);
    // ends updating product here

    setFormLoading(false);
    setUpdateAmountModal({
      childComponent: null,
      open: false,
    });
    setShowSimpleNoti({
      open: true,
      message: null,
      title: callResult.data.message,
      type: SimpleNotificationType.Success,
    });
  };

  const changeSubscription = (product, index) => {
    setUpdateAmountModal({
      childComponent: (
        <ChangeSubscriptionForm
          frequencies={frequencies}
          index={index}
          product={product}
          loading={formLoading}
          setOpen={setUpdateAmountModal}
          updateAmountCall={updateAmountCall}
        />
      ),
      open: true,
    });
  };

  const cancelSubscription = async function (product, index) {
    let callResult = await makePostApiCall(`cancelSubscription/${product.id}`);

    const updatedSubscriptions = subscriptions.filter(
      (prod) => prod.id !== product.id
    );

    setSubscriptions(updatedSubscriptions);
    setConfirmationModal({
      action: null,
      childComponent: null,
      open: false,
    });
    setShowSimpleNoti({
      open: true,
      message: null,
      title: callResult.data.message,
      type: SimpleNotificationType.Success,
    });
  };

  const openCancelSubscriptionModal = (product, index) => {
    setConfirmationModal({
      action: () => cancelSubscription(product, index),
      open: true,
    });
  };

  const skipSubscription = async function (product, index) {
    let callResult = await makePostApiCall(`skipSubscription/${product.id}`);

    // starts updating subscription here
    const currentSubscriptions = [...subscriptions];
    const updatedSubscription = {
      ...subscriptions[index],
      delivery_status: "6",
    };

    const updatePayload = {
      index: index,
      item: updatedSubscription,
    };

    const updatedSubscriptions = updateObjectInArray(
      currentSubscriptions,
      updatePayload
    );

    setSubscriptions({
      ...subscriptions,
      data: updatedSubscriptions,
    });
    // ends updating subscription here

    setSkipSubscriptionModal({
      action: null,
      childComponent: null,
      open: false,
    });
    setShowSimpleNoti({
      open: true,
      message: null,
      title: callResult.data.message,
      type: SimpleNotificationType.Success,
    });
  };

  const openSkipSubscriptionModal = (product, index) => {
    setSkipSubscriptionModal({
      action: () => skipSubscription(product, index),
      open: true,
    });
  };

  return {
    cancelSubscription,
    changeSubscription,
    confirmationModal,
    getFrequencies,
    getUserSubscriptions,
    formLoading,
    loading,
    openCancelSubscriptionModal,
    openSkipSubscriptionModal,
    setConfirmationModal,
    setShowSimpleNoti,
    setSkipSubscriptionModal,
    setUpdateAmountModal,
    showSimpleNoti,
    skipSubscriptionModal,
    subscriptions,
    updateAmountModal,
  };
}
