import React, { useEffect, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { RouterProvider } from "react-router-dom";
import router from "../router/router";
import "./App.css";

import * as actions from "../store/actions/index";

import ErrorBoundary from "../hoc/ErrorBoundary.js";

import { withRouter } from "../shared/utility";
import DefaultLayout from "../components/layouts/DefaultLayout";
import AuthenticateUser from "../views/authentication/AuthenticateUser";
import RequestEmailAuthentication from "../views/authentication/RequestEmailAuthentication";

// todo oggy here check if authenticated then do the proper router
function App(props) {
  const { authCheckState } = props;

  useEffect(() => {
    authCheckState();
  }, [authCheckState]);

  let routes = null;

  const authToken = localStorage.getItem("authToken");

  let authRedirect = null;
  if (authToken) {
    if (props.isAuthenticated) {
      routes = (
        <Routes>
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      );
    }
  } else {
    console.log("oggy");
    routes = (
      <Routes>
        <Route path="/authenticate" element={<AuthenticateUser />} />
        <Route
          path="/email-authenticate"
          element={<RequestEmailAuthentication />}
        />

        <Route path="*" element={<RequestEmailAuthentication />} />
      </Routes>
    );
  }

  return (
    <div className="h-full">
      {authRedirect}
      <ErrorBoundary>
        <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
      </ErrorBoundary>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.authToken !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authCheckState: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
