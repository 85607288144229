import { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import * as actions from "../../store/actions/index";

import EmailAuthenticationForm from "../../components/forms/EmailAuthencationForm";
import { AuthenticationViewModel } from "../../viewmodels/AuthenticationViewModel";

const AuthenticateUser = (props) => {
  const {
    emptyModalOpen,
    formMessage,
    loading,
    requestAuthenticationTokenToEmail,
    setEmptyModalOpen,
  } = AuthenticationViewModel();

  const [searchParams, setSearchParams] = useSearchParams();
  console.log("searchParams", searchParams.get("auth"));

  useEffect(() => {
    document.title = "Fridge Filters Subscriptions - Authenticating...";
    props.onAuth(searchParams.get("sess"), searchParams.get("auth"));
  }, []);

  return (
    <div className="h-full">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            src="https://cfrouting.zoeysite.com/cdn-cgi/image/format=auto,fit=scale-down,quality=70/https://s3.amazonaws.com/zcom-media/sites/a0i0L00000QytFYQAZ/media/mediamanager/Fridge-Filters_Clear_1600x405_.png"
            alt="Fridge Filters Logo"
          />
        </div>
        {formMessage.show ? (
          <div className="text-md mt-5 text-center font-medium sm:mx-auto sm:w-full sm:max-w-sm">
            {formMessage.show ? formMessage.message : null}
          </div>
        ) : (
          <>
            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Currently authenticating...
              </h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    fname: state.auth.fname,
    lname: state.auth.lname,
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (id, token) => dispatch(actions.auth(id, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateUser);
